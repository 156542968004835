import { Sendform } from '../libs/sendform/sendform2';
import { Slider } from '../libs/slider';
import '../../node_modules/select2/dist/js/select2.min.js';
//JQuery Mask
import '../libs/maskedinput.min.js';
import '../../node_modules/bootstrap/dist/js/bootstrap.bundle';
import '../../node_modules/jquery-validation/dist/jquery.validate.min';
import '../../node_modules/magnific-popup/dist/jquery.magnific-popup.js';

$( document ).ready(function() {
    let projectApp = new App();
    global.app = projectApp;
    projectApp.init();
});

class App{
    // Define global visible variable inside app 
    constructor(){}
    init(){

        //Fixed header on mobile
        $(window).scroll(function(){
            let header = $('.header'),
                scroll = $(window).scrollTop()
            if (scroll >= 60) header.addClass('__fixed')
            else header.removeClass('__fixed')
        });

        //Scroll
        let $links = $('.js_scroll-lnk');
        $links.each(function() {
            $(this).click(function(){
                $links.each(function() {
                    $('body').removeClass('__overlay')
                    $(this).removeClass('__active')
                })
                $(this).addClass('__active')
                $('body').removeClass('__overlay')
                $('.js_menu-cnt').removeClass('__show-menu')
                $('.js_menu-btn').removeClass('__active')
                $('html, body').animate({
                    scrollTop: $( $(this).attr('href') ).offset().top - 20
                }, 1000)
                return false
            })
        });

        //Send form
        let $forms = $('.js_send-form')
        $forms.each(function() {
            new Sendform(this, {
                msgValEachField: true,
                msgError: 'Sorry, cannot send this form',
                modalOpen: false
            })
        });

        //Mobile menu
        $('body').on('click', '.js_menu-btn', function(e) {
            e.preventDefault()
            $(this).toggleClass('__active')
            if($(this).hasClass('__active')){
                $('body').addClass('__overlay')
                $('.js_menu-cnt').addClass('__show-menu')
            } else{
                $('.js_menu-cnt').removeClass('__show-menu')
                $('body').removeClass('__overlay')
            }
        });

        //Slider reviews
        new Slider('.js_carousel-reviews',
            '.js_carousel-reviews-prev',
            '.js_carousel-reviews-next',
            true, {
                loop: true,
                margin: 30,
                nav: false,
                autoHeight: false,
                autoplay: false,
                autoplayHoverPause: true,
                dots: false,
                paginationNumbers: false,
                responsive:{
                    1025: {
                        items: 3,
                    },
                    681: {
                        items: 2,
                        margin: 15
                    },
                    320: {
                        items: 1,
                        margin: 0
                    }
                }
            });

        // Init select 2
        function initDefaultSelect(el) {
            $(el).each(function(){
                let $thisSelect = $(this)
                $thisSelect.select2({
                    minimumResultsForSearch: Infinity,
                    dropdownParent: $thisSelect.parent()
                })
            });
        }

        initDefaultSelect('.js_select');

        /**
         * Clone dynamic fields
         */
        function addCopyElement(btnAdd, btnRemove, elBlk, elWrap, elCnt) {
            $('body').on('click', btnAdd, function(e) {
                e.preventDefault()
                let $elWrap = $(this).closest(elWrap)
                let countEl = $elWrap.attr('data-count')
                ++countEl
                $elWrap.attr('data-count', countEl)
                if (countEl === 0) {
                    $elWrap.find(btnRemove).addClass('__disable')
                } else {
                    $elWrap.find(btnRemove).removeClass('__disable')
                }
                let copyEl = ''
                copyEl = `
                <div class="js_clone-blk">
                                <div class="wrap-inp-row">
                                    <div class="wrap-select">
                                        <label class="inp-lbl">Type of vehicle</label>
                                        <select name="transport[${countEl}][type_of_vehicle]" class="js_select js_clone-el js_select-type"></select>
                                    </div>
                                    <div class="wrap-select">
                                        <label class="inp-lbl">Year</label>
                                        <select name="transport[${countEl}][year]" class="js_select js_clone-el js_select-year"></select>
                                    </div>
                                </div>
                                <div class="wrap-inp-row">
                                    <div class="wrap-select">
                                        <label class="inp-lbl">Make</label>
                                        <select name="transport[${countEl}][make]" class="js_select js_clone-el js_select-make"></select>
                                    </div>
                                    <div class="wrap-select">
                                        <label class="inp-lbl">Model</label>
                                        <select name="transport[${countEl}][model]" class="js_select js_clone-el js_select-model"></select>
                                    </div>
                                </div>
                                <div class="wrap-radio">
                                    <div class="radio-el">
                                        <input type="radio" id="private_${countEl}" class="radio js_clone-el" checked
                                               name="transport[${countEl}][type]"
                                               value="private"
                                               required>
                                        <label for="private_${countEl}" class="radio-lbl">
                                            <span class="radio-lbl-txt">Private</span>
                                        </label>
                                    </div>
                                    <div class="radio-el">
                                        <input type="radio" id="business_${countEl}" class="radio js_clone-el"
                                               name="transport[${countEl}][type]"
                                               value="business"
                                               required>
                                        <label for="business_${countEl}" class="radio-lbl">
                                            <span class="radio-lbl-txt">Business</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                `
                $elWrap.find(elCnt).append(copyEl)
                let selectTypeData = $elWrap.find('.js_select-type').first()
                let $selectType = $elWrap.find('.js_select-type').last()
                $selectType.append(selectTypeData.html())
                let selectYearData = $elWrap.find('.js_select-year').first()
                let $selectYear = $elWrap.find('.js_select-year').last()
                $selectYear.append(selectYearData.html())
                let selectMakeData = $elWrap.find('.js_select-make').first()
                let $selectMake = $elWrap.find('.js_select-make').last()
                $selectMake.append(selectMakeData.html())
                let selectModelData = $elWrap.find('.js_select-model').first()
                let $selectModel = $elWrap.find('.js_select-model').last()
                $selectModel.append(selectModelData.html())
               initDefaultSelect(elBlk + ' ' +'.js_select')
            })
        }

        function removeCopyElement(btnRemove, elBlk, elWrap, elCnt) {
            $('body').on('click', btnRemove, function(e) {
                e.preventDefault()
                let $elWrap = $(this).closest(elWrap)
                let countEl = $elWrap.attr('data-count')
                --countEl
                $elWrap.attr('data-count', countEl)
                $elWrap.find(elCnt).find(`${elBlk}:last`).remove()
                if (countEl === 0 || $('.js_clone-blk').length === 0) {
                    $elWrap.find(btnRemove).addClass('__disable')
                } else {
                    $elWrap.find(btnRemove).removeClass('__disable')
                }
            })
        }

        function cloneInputs(el, btnAdd, btnRemove, elBlk, elWrap, elCnt) {
            addCopyElement(btnAdd, btnRemove, elBlk, elWrap, elCnt)
            removeCopyElement(btnRemove, elBlk, elWrap, elCnt)
        }

        cloneInputs('.js_clone-el', '.js_clone-add', '.js_clone-remove', '.js_clone-blk', '.js_clone-wrap', '.js_clone-cnt');

        /**
         * Removing spinner.
         */
        function removeSpinner() {
            if(!document.querySelector('#formsendHover')) return
            document.querySelector('#formsendHover').remove()
        }

        /**
         * Send copy fields form
         */
        function initSendForm(formClass) {
            $(formClass).validate({
                ignore: [],
                rules: {
                    email: {
                        email: true
                    }
                },
                errorPlacement: function() {
                    return true
                },
                invalidHandler: function(event, validator) {
                    if (validator.errorList.length > 0) {
                        if ($(validator.errorList[0].element).closest('.js_clone-blk').length) {
                            $('html, body').animate({
                                scrollTop: $(validator.errorList[0].element).closest('.js_clone-blk').offset().top,
                            }, 200)
                        }
                    }
                },
                submitHandler: function (form) {
                    /**
                     * Adding spinner.
                     */
                    let div = document.createElement('div')
                    div.innerHTML= '<div class="form-loading"></div>'
                    div.id= 'formsendHover'
                    $(formClass)[0].appendChild(div)
                    $(this.currentForm).find('.js_form-error').text('')
                    let dataString = $(form).serialize()

                    $(this.currentForm).find('.js_form-error').text('')
                        $.ajax({
                            type: $(this.currentForm).attr('method'),
                            url: $(this.currentForm).attr('action'),
                            data: dataString,
                            success: (data) => {
                                removeSpinner()
                                return false
                            },
                            error: (data) => {
                                removeSpinner()
                                $(this.currentForm).find('.js_form-error').text('data.message')
                                let dataObj = data.responseJSON.errors
                                if (dataObj) {
                                    $.each(dataObj, (idx, el) => {
                                        let inputName = idx
                                        let nameEl = '';
                                        if (inputName.includes('.')) {
                                            let nameStr = inputName.split('.');
                                            let newNameStr = '';
                                            nameStr.map((val, i) => {
                                                if (i === 0) {
                                                    newNameStr += val
                                                } else {
                                                    newNameStr += `[${val}]`
                                                }
                                            })
                                            nameEl = newNameStr
                                        } else {
                                            nameEl = idx
                                        }

                                        let $inpFirst = $(this.currentForm).find(`[name='${nameEl}']`)
                                        if($inpFirst && $inpFirst.length) {
                                            $inpFirst.addClass('error')
                                            if (nameEl === Object.keys(dataObj)[0]) {
                                                $('html, body').animate({
                                                    scrollTop: $inpFirst.offset().top - 65,
                                                }, 200)
                                            }
                                        }
                                    })
                                }
                            }
                        })
                        return false
                }
            })
        }

        initSendForm('.js_send-main-from')
        initSendForm('.js_send-add-from')

        openPopup('.js_modal-open')
        closePopup('.js_modal-close')

        function openPopup(btn) {
            $('body').on('click', btn, function(event) {
                event.preventDefault()
                $('body').addClass('__overlay')
                let $modal = $(this).attr('href')
                $.magnificPopup.open({
                    showCloseBtn: false,
                    type: 'inline',
                    tLoading: 'Загрузка...',
                    items: {
                        src: $modal
                    },
                    callbacks: {
                        afterClose: () => {
                            $('body').removeClass('__overlay')
                        }
                    }
                })
            })
        }

        function closePopup(el) {
            $('body').on('click', el, function(event) {
                event.preventDefault()
                console.log(1)
                $('body').removeClass('__overlay')
                $.magnificPopup.close()
            })
        }
    }
};
